function my_timeline_navigation_bar() {


  // console.log('timeline_nav_bar');

    var current_scroll_position = jQuery(window).scrollTop();
    
    if(current_scroll_position >= jQuery('.featured_image').height()) {
      jQuery("#timeline-navigation").addClass('sticky');
      jQuery('section.timeline').addClass('margin');
      jQuery('.progress').addClass('visible');
      jQuery('#timeline-menu-toggle, .navbar-timeline-mobile').addClass('active');
    } else {
      jQuery("#timeline-navigation").removeClass('sticky');
      jQuery('section.timeline').removeClass('margin');
      jQuery('.progress').removeClass('visible');
      jQuery('#timeline-menu-toggle, .navbar-timeline-mobile').removeClass('active');
    }

    if((jQuery('.featured_image').length > 0) || (jQuery('#homepage-hero').length > 0)) {
      var feat_image_calc = jQuery('.featured_image, #homepage-hero').height() - (jQuery('.top-bar-backdrop').height() * .5);
      if(current_scroll_position >= feat_image_calc) {
        jQuery('.top-bar').addClass('small-header');
      } else {
        jQuery('.top-bar').removeClass('small-header');
      }
    } else {
      if(current_scroll_position >= jQuery('.top-bar-backdrop').height()) {
        jQuery('.top-bar').addClass('small-header');
      } else {
        jQuery('.top-bar').removeClass('small-header');
      }      
    }

}

function timeline_mobile_version() {
  // console.log('timeline_mobile');
    if(jQuery(window).width() <= 640) {
         jQuery('.timeline-post').removeClass('vc_row-has-fill vc_row-o-columns-middle vc_row-o-content-middle vc_row-flex');
       } else {
         jQuery('.timeline-post').addClass('vc_row-has-fill vc_row-o-columns-middle vc_row-o-content-middle vc_row-flex');
       }
  }

  function timeline_progressbar() {
    // console.log('time_prog');
    var current_scroll_position = jQuery(window).scrollTop();
    var document_height = (jQuery(document).height() - jQuery(window).height());
    var document_height_without_bottom = document_height - (jQuery('.bottom-of-timeline').height() + jQuery('.footer').height());
    var scrollPercent = 100 * (jQuery(window).scrollTop() / document_height_without_bottom);

    jQuery('.progress-meter').css('width', scrollPercent +"%"  );
    jQuery('.progress').attr('aria-valuetext', Math.round(scrollPercent) +" percent"  );

    if(scrollPercent >= 100) {
      jQuery('.progress').removeClass('visible');
      jQuery('#timeline-menu-toggle, .navbar-timeline-mobile').removeClass('active');
    }

    if(scrollPercent >= 102) {
      jQuery('#timeline-menu-toggle').addClass('bottom');
      jQuery('.progress').addClass('bottom');
    } else {
      jQuery('#timeline-menu-toggle').removeClass('bottom');
      jQuery('.progress').removeClass('bottom');
    }

}

/************************
Timeline Array Population
*************************/

function detect_timeline_eras() {
  //console.log('time_era');
    var captured_era_classes = document.getElementsByClassName('timeline-item');
    var sanitized_captured_era_classes = [];

    jQuery.each(captured_era_classes, function( index, value ) {
      var sanitized_className = value.className.replace("timeline-item era-","")
      sanitized_captured_era_classes.pushIfNotExist(sanitized_className, function(e) { 
          return e === sanitized_className; 
      });
    });
    return sanitized_captured_era_classes;

}

// check if an element exists in array using a comparer function
// comparer : function(currentElement)
Array.prototype.inArray = function(comparer) { 
    for(var i=0; i < this.length; i++) { 
        if(comparer(this[i])) return true; 
    }
    return false; 
}; 

// adds an element to the array if it does not already exist using a comparer 
// function
Array.prototype.pushIfNotExist = function(element, comparer) { 
    if (!this.inArray(comparer)) {
        this.push(element);
    }
};

// Timeline block visibility

function timeline_blocks(timelineBlocks, offset) {
  // console.log('time_block');
    (!window.requestAnimationFrame) 
      ? setTimeout(function(){ showBlocks(timelineBlocks, offset); }, 100)
      : window.requestAnimationFrame(function(){ showBlocks(timelineBlocks, offset); });

    var eras = detect_timeline_eras();

    // Loop through each era to see if it is visible
    jQuery.each(eras, function( index, value ) {

      if (jQuery('.timeline-item.era-'+value+' .timeline-post').is(':in-viewport(.timeline-item.era-'+value+' .timeline-post)')) {
          jQuery('.timeline-menu-item.era-'+value).addClass('active');
      } else {
          jQuery('.timeline-menu-item.era-'+value).removeClass('active');
      }      

    });
    

}

function hideBlocks(blocks, offset) {
  console.log('hide_block');
    blocks.each(function(){
      ( jQuery(this).offset().top > jQuery(window).scrollTop()+jQuery(window).height()*offset ) && jQuery(this).find('.timeline-circle, .timeline-post').addClass('out-of-view');
    });
  }

function showBlocks(blocks, offset) {
  // console.log('show_block');
  blocks.each(function(){
    ( jQuery(this).offset().top <= jQuery(window).scrollTop()+jQuery(window).height()*offset && jQuery(this).find('.timeline-circle').hasClass('out-of-view') ) && jQuery(this).find('.timeline-circle, .timeline-post').removeClass('out-of-view').addClass('bounce-in');
  });
}
