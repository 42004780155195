/*
|==========================================================================
| Initialize Foundation
|==========================================================================
| 
| This initializes Foundation.
|
|==========================================================================
|
*/

jQuery(document).foundation();

// Mega Menu Connection
// Important for Foundation Dropdown Pane Label Accessibility Correction
jQuery('#mega-menu > .dropdown-pane').each(function (i) {
    var this_element = jQuery(this);
    var aria_labelledby = this_element.data('menu-item-id');
    this_element.attr('aria-labelledby', aria_labelledby);
});

// // Search Aria Cleanup
// jQuery('#searchdropdown').attr('aria-labelledby', 'search-toggle');