/*
|==========================================================================
| WordPress + Foundation
|==========================================================================
| 
| These functions make sure WordPress and Foundation play nice together,
| and this is also where we declare functions defined in this folder.
|
|==========================================================================
|
*/

document.addEventListener( "DOMContentLoaded", function() {

  // Evolving function for WCAG guidelines
  v8_wcag();

  // Detect browser and device
  detectBrowserAndDevice();

  // Enable skrollr
  // var s = skrollr.init();

  // Gravity Forms Placeholder Clearing
  // gravity_forms_placeholders();

  // Inline SVG replacement
  // inline_svg_replacement();

  // Isotope Masonry
  // isotope_masonry();

  // TablePress Cleanup
  // tablepress();

  // Event Target Testing
  jQuery(document).click(function(event) {

  });

  // Window scroll detection
  jQuery(window).bind("scroll", function(event) {

    my_timeline_navigation_bar();
    timeline_blocks(timelineBlocks, offset);
    timeline_progressbar();

  });

  // Remove empty P tags created by WP inside of Accordion and Orbit
  jQuery('.accordion p:empty, .orbit p:empty').remove();

	 // Makes sure last grid item floats left
  jQuery('.archive-grid .columns').last().addClass( 'end' );

	// Adds Flex Video to YouTube and Vimeo Embeds
  jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
    if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
      jQuery(this).wrap("<div class='widescreen flex-video'/>");
    } else {
      jQuery(this).wrap("<div class='flex-video'/>");
    }
  });

  onElementHeightChange(document.body, function () {
      AOS.refresh({
          // disable: 'mobile'
      });
  });


  jQuery(window).resize(function () {
      AOS.refresh({
          // disable: 'mobile'
      });

      timeline_mobile_version(); // timeline for mobile
  });

  // Menu when open and close animate
  jQuery('#mega-menu > .dropdown-pane').each(function (i) {
      
    var this_element = jQuery(this);

    this_element.on( "show.zf.dropdown", function() {
      // console.log( this_element + ' open' );
      
      this_element.toggleClass('displayblock');
      setTimeout(function(){
        this_element.toggleClass('open');
        this_element.toggleClass('megamenu_open');
      },50);
    });

    this_element.on( "hide.zf.dropdown", function() {
      // console.log( this_element + ' hide' );
      this_element.toggleClass('open');
      this_element.toggleClass('megamenu_open');
      setTimeout(function(){
        this_element.toggleClass('displayblock');
      },1000);
    });
      
  });

  // Homepage when video ended show/hide buttons
  if(jQuery('.page-template-template-homepage').length > 0) {
    document.getElementById('video').addEventListener('ended',endVideoCallback,false);
  
    jQuery(' .hero button[data-play]').click(function(event) {
      jQuery(this).addClass('hide');
      jQuery('.hero button[data-pause]').removeClass('hide');
      video = jQuery('#video');
      video.get(0).play();
    });
  
    jQuery('.page-template-template-homepage .hero button[data-pause]').click(function(event) {
      jQuery(this).addClass('hide');
      jQuery('.hero button[data-play]').removeClass('hide');
      video = jQuery('#video');
      video.get(0).pause();
    });

  } 

  // Prepare timeline items
  var timelineBlocks = jQuery('li.timeline-item'), offset = 0.8;

  // Timeline initialization
  detect_timeline_eras();
  my_timeline_navigation_bar(); 
  timeline_mobile_version();
  timeline_blocks(timelineBlocks, offset);

  // Mobile Menu Event
  jQuery('#timeline-menu-toggle').click(function(e){
    console.log('time_menu_toggle');
    jQuery('#timeline-navigation-mobile').toggleClass('is-open');
    jQuery("#mobile-menu-toggle").removeClass('open');
    jQuery('.mobile-menu-container').removeClass('is-open'); 
    e.stopPropagation();
    return false;
  });

  jQuery('.timeline-menu-item').click(function(e){
    console.log('time_menut_item');
    var era = jQuery(this).attr('data-era');
    var timeline_item = '.timeline-item.'+era;
    // console.log(timeline_item);
    jQuery(window).scrollTo(jQuery(timeline_item));
    e.stopPropagation();
    return false;
  });


});

/*
|==========================================================================
| Use this function to fire events after the entire window has loaded,
| including images and CSS files.
|==========================================================================
*/

window.onload = function() {
  AOS.init({

  // Global settings:
  disable: 'mobile', // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  // startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  // initClassName: 'aos-init', // class applied after initialization
  // animatedClassName: 'aos-animate', // class applied on animation
  // useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  // disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  // debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  // throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
  

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  // offset: 120, // offset (in px) from the original trigger point
  // delay: 0, // values from 0 to 3000, with step 50ms
  // duration: 400, // values from 0 to 3000, with step 50ms
  // easing: 'ease', // default easing for AOS animations
  // once: false, // whether animation should happen only once - while scrolling down
  // mirror: false, // whether elements should animate out while scrolling past them
  // anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation);
});

};

function onElementHeightChange(elm, callback) {
  var lastHeight = elm.clientHeight
  var newHeight;

  (function run() {
      newHeight = elm.clientHeight;
      if (lastHeight !== newHeight) callback();
      lastHeight = newHeight;

      if (elm.onElementHeightChangeTimer) {
          clearTimeout(elm.onElementHeightChangeTimer);
      }

      elm.onElementHeightChangeTimer = setTimeout(run, 200);
  })();
}

function endVideoCallback(e) {
  jQuery('button[data-play]').removeClass('hide');
  jQuery('button[data-pause]').addClass('hide');
}